import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

export const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 110) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        relativePath
      }
    }
  `)
  const sizes = "(max-width: 599px): 110px, 140px"
  return (
    <ImageWrapper>
      <Img fluid={{...data.file.childImageSharp.fluid, sizes: sizes}} />
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div`
  overflow: hidden;
  width: 140px;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 110px;
  }
`


// import React from "react"
// import { StaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

// export const Logo = (
//     <StaticQuery
//       query={graphql`
//         query LogoQuery {
//             file(relativePath: { eq: "content/images/logo.jpg" }) {
//             childImageSharp {
//                 # Specify the image processing specifications right in the query.
//                 # Makes it trivial to update as your page's design changes.
//                 fixed(width: 125, height: 125) {
//                 ...GatsbyImageSharpFixed
//                 }
//             }
//             }
//         }
//     `}
//     render={ data => (
//         <div>
//             <h1>Hello gatsby-image</h1>
//             <Img fixed={data.file.childImageSharp.fixed} />
//         </div>
//     )}
//   />
// )

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "content/images/logo.jpg" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `


// import React from "react"
// import { graphql } from "gatsby"
// export default () => (
//   <StaticQuery
//     query={graphql`
//       query HeadingQuery {
//         site {
//           siteMetadata {
//             title
//           }
//         }
//       }
//     `}
//     render={data => (
//       <header>
//         <h1>{data.site.siteMetadata.title}</h1>
//       </header>
//     )}
//   />
// )