
import styled, { css } from "styled-components"
import { mix, transparentize } from "polished"

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Card = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.background};
  
  &:hover {
    background-color: ${props => mix(0.80, props.theme.color.background, props.theme.color.foreground)};
  }

  box-shadow: 0 0.5rem 1rem -0.5rem ${props => transparentize(0.9, props.theme.color.black)};
  border-radius: ${props => props.theme.radius.small};

  --paper-padding-y: 0rem;
  --paper-padding-x: 0rem;
  padding: var(--paper-padding-y) var(--paper-padding-x);

  flex: 0 1 calc(50% - 1em);

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    flex: 1 0 calc(100%);
  }

  ${props =>
    props.article &&
    css`
      flex-grow: 0 !important;
      margin-bottom: 2rem !important;
    `};

  ${props =>
    props.center &&
    css`
      text-align: center;
    `};

  ${props =>
    props.theme.isDarkMode &&
    css`
      background-color: ${props =>
        mix(0.92, props.theme.color.background, props.theme.color.foreground)};
    `};

  > *:last-child,
  > div > *:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    margin: 0;
  }

  pre[class*="language-"] {
    border-radius: 0;
    padding-left: var(--paper-padding-x);
    padding-right: 0;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      margin: var(--paper-padding-y) -var(--paper-padding-x) !important;
      padding-left: var(--paper-padding-x);
    }
  }
`

// ${Image}, .gatsby-resp-image-wrapper, pre[class*="language-"] {
//     margin: var(--paper-padding-y) calc(var(--paper-padding-x) * -1) !important;
//     overflow: hidden;
//   }