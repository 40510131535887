import React from "react"
import { Wrapper as ImportedWrapper } from "./style"
import styled from "styled-components"
import { useStaticQuery } from "gatsby"
import { Star } from "styled-icons/boxicons-regular"
import { Star as StarFull, StarHalf } from "styled-icons/boxicons-solid"
import { FacebookSquare, Google } from "styled-icons/boxicons-logos"
import Img from "gatsby-image"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Wrapper = styled(ImportedWrapper)`
  .carousel .slide {
    background: ${props => props.theme.color.primary};
  }
`

const StyledStar = styled(Star)`
  width: 24px;
  height: 24px;
  color: green;
  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 16px;
    height: 16px;
  }
`

const StyledStarFull = styled(StarFull)`
  width: 24px;
  height: 24px;
  color: green;
  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 16px;
    height: 16px;
  }
`

const StyledStarHalf = styled(Star)`
  width: 24px;
  height: 24px;
  color: green;
  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 16px;
    height: 16px;
  }
`

const RatingWrapper = styled.div`
  background-color: ${props => props.theme.color.primary};
  padding: 1em;
  display: block;
  // margin: 1em 0em;
  text-align: left;
`

const RatingContentWrapper = styled.div`
  display: block;
  overflow: hidden;
`

const RatingContentWrapperWrapper = styled.div`
  display: block;
  overflow: hidden;
`

const RatingAuthorImage = styled.div`
  width: 64px;
  height: 64px;
  // background-color: orange;
  display: block;
  float: left;
  margin-right: 1em;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 32px;
    height: 32px;
    margin-right: .5em;
  }
`

const RatingDate = styled.div`
  font-size: 0.7em;
  display: block;
  overflow: hidden;
`

const RatingStars = styled.div`
  display: block;
  overflow: hidden;
`

const RatingStar = styled.div`
  display: inline-block;
`

const RatingAuthor = styled.a`
  display: block;
  float: left;
  margin-right: 1em;
  color: ${props => props.theme.color.foreground};

  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
`

const RatingText = styled.div`
  display: block;
  margin-top: 1em;
  font-style: italic;
`

const RatingSource = styled.div`
  width: 32px;
  height: 32px;
  display: block;
  float: right;
  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 16px;
    height: 16px;
  }
`

const Copyright = styled.div`
  font-size: 0.8rem;
  text-align: center;
  padding-bottom: 1em;
`

function roundHalf(number) {
  var value = (number * 2).toFixed() / 2;
  return value;
}

function round(number) {
  var value = (number * 2).toFixed();
  return value;
}

// reviews: allGooglePlacesReview(sort: {fields: time, order: DESC}, filter: {rating: {eq: 1} }) {

export const Footer = styled(({ ...styleProps }) => {
  // const googlePlacesReviews = useStaticQuery(graphql`
  //   query {
  //     place: googlePlacesPlace {
  //       rating
  //       user_ratings_total
  //     }
  //     reviews: allGooglePlacesReview(sort: {fields: time, order: DESC}) {
  //       edges {
  //         node {
  //           author_name
  //           rating
  //           text
  //           author_url
  //           googlePlacesId
  //           profile_photo_url
  //           language
  //           time
  //           remoteImage {
  //             childImageSharp {
  //               fluid(quality: 70, maxWidth: 64, toFormat: PNG) {
  //                 ...GatsbyImageSharpFluid_withWebp
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }`)

  // let roundedPlaceRating = roundHalf(googlePlacesReviews.place.rating)
  // let halveRating = roundedPlaceRating !== round(googlePlacesReviews.place.rating) ? 1 : 0

  // const filteredReviews = googlePlacesReviews.reviews.edges.filter((review) => {
  //   return review.node.rating > 2
  // })

  return (
    <footer {...styleProps}>
      <Wrapper>
        {/* <RatingWrapper>
          {googlePlacesReviews.place.rating}
          <RatingStars>
            {
              Array.apply(null, { length: roundedPlaceRating }).map((e, i) => (
                <RatingStar>
                  <StarFull color="#A0CE4E" size="24" />
                </RatingStar>
              ))
            }
            {
              halveRating === 1 ? <StarHalf color="#A0CE4E" size="24" /> : false
            }
            {
              Array.apply(null, { length: 5 - roundedPlaceRating - halveRating }).map((e, i) => (
                <RatingStar>
                  <Star color="#A0CE4E" size="24" />
                </RatingStar>
              ))
            }
          </RatingStars>
          ({googlePlacesReviews.place.user_ratings_total})
        </RatingWrapper> */}
{//        <Carousel 
//          autoPlay={true}
//          infiniteLoop={true}
//          interval={5000}
//          showStatus={false}
//          showIndicators={false}
//          axis="horizontal"
//          transitionTime={500}
//          swipeable={false}
//        >
//        {filteredReviews.map(review => {
//          return (
//            <RatingWrapper>
//              <RatingAuthorImage>
//                <Img fluid={review.node.remoteImage.childImageSharp.fluid} />
//              </RatingAuthorImage>
//              <RatingContentWrapper>
//                <RatingContentWrapperWrapper>
//                  <RatingSource>
//                    {/* <FacebookSquare color="#3b5998"/> */}
//                    <Google />
//                  </RatingSource>
//                  <RatingAuthor href={review.node.author_url} target="_blank" rel="noopener">{review.node.author_name}</RatingAuthor>
//                  <RatingStars>
//                    {
//                      Array.apply(null, { length: review.node.rating }).map((e, i) => (
//                        <RatingStar>
//                          <StyledStarFull/>
//                        </RatingStar>
//                      ))
//                    }
//                    {
//                      Array.apply(null, { length: 5 - review.node.rating }).map((e, i) => (
//                        <RatingStar>
//                          <StyledStar />
//                        </RatingStar>
//                      ))
//                    }
//                  </RatingStars>
//                  <RatingDate>{new Date(review.node.time*1000).toLocaleDateString()}</RatingDate>
//                </RatingContentWrapperWrapper>
//                <RatingText>{review.node.text}</RatingText>
//              </RatingContentWrapper>
//            </RatingWrapper>
//          )
//        })}
//        </Carousel>
        }
        <Copyright>
          © {new Date().getFullYear()} – De Klevenhorst
        </Copyright>
      </Wrapper>
    </footer>
  )
})`
  min-height: 3rem;
  background: ${props => props.theme.color.primary};
`
